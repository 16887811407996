<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center"
          ><span style="color: #fff">
            {{ msg }}
          </span></v-flex
        >
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-5 pa-5>
      <v-flex xs12 sm8 md11>
        <v-layout wrap>
          <v-flex xs12 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px"
              >Blog List</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm4 md1 pt-2 text-right>
        <v-dialog
          :retain-focus="false"
          v-model="dialog"
          persistent
          max-width="800px"
          :key="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="green" v-bind="attrs" v-on="on"> ADD </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="headline">Add Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        outlined
                        dense
                        v-model="tittle"
                        label="Tittle"
                        required
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="12">
                      <v-textarea
                        outlined
                        dense
                        v-model="content"
                        label="Content"
                        required
                      ></v-textarea>
                    </v-col> -->
                    <v-col cols="12">
              <span> Content</span>
            </v-col>
            <v-col cols="12">
              <div id="app">
                <vue-editor v-model="content"></vue-editor>
              </div>
            </v-col>
                    <!-- <v-col cols="12">
                        <span>Add Description</span>
                      </v-col>
                      <v-col cols="12">
                        <div id="app">
                          <vue-editor v-model="description"></vue-editor>
                        </div>
                      </v-col> -->
                    <v-col cols="12">
                      <ImageComp
                        :singleImage="image"
                        @stepper="winStepper"
                        :heading="'Upload Image'"
                        :componentType="'blogImage'"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="add()"> Save </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start v-if="blog.length > 0">
      <v-flex xs12 sm6 md6 pa-2 v-for="(item, i) in blog" :key="i">
        <v-card>
          <v-layout wrap py-5 px-4>
            <v-flex xs12>
              <v-img :src="mediaUURL + item.image" contain
                ><template v-slot:placeholder> <ImageLoader /> </template
              ></v-img>
            </v-flex>
            <v-flex xs12>
              <span style="font-family: poppinssemibold; font-size: 20px">{{
                item.tittle
              }}</span>
            </v-flex>
            <v-flex xs12>
              <span style="font-family: poppinsregular; font-size: 15px" v-html="item.content"></span>
            </v-flex>
            <v-layout wrap>
              <v-flex xs6 sm6 pa-2>
                <v-btn
                  small
                  block
                  class="mr-2"
                  outlined
                  color="green"
                  @click="editSlider(item)"
                >
                  Edit
                </v-btn>
              </v-flex>
              <v-flex xs6 sm6 pa-2>
                <v-dialog v-model="item.delete" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="mr-2"
                      outlined
                      block
                      v-on="on"
                      v-bind="attrs"
                      color="red"
                    >
                      Delete
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title
                      >Are you sure you want to delete this blog?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="item.delete = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItem(item)"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-else>
      <v-flex xs12 py-16 text-center>
        <span style="font-family: poppinsbold; font-size: 25px"
          >No blog available</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="green"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Blog</span>
        </v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-col cols="12" sm="12">
              <v-text-field
                outlined
                dense
                v-model="editingitem.tittle"
                label="Tittle"
                required
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="12">
              <v-textarea
                outlined
                dense
                v-model="editingitem.content"
                label="Content"
                required
              ></v-textarea>
            </v-col> -->
            <v-col cols="12">
              <span> Content</span>
            </v-col>
            <v-col cols="12">
              <div id="app">
                <vue-editor v-model="editingitem.content"></vue-editor>
              </div>
            </v-col>
            <v-col cols="12">
              <ImageComp
                :singleImage="editingitem.image"
                @stepper="winStepper"
                :heading="'Upload Image'"
                :componentType="'blogImage'"
              />
            </v-col>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editdialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="edit(editingitem._id)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
  components: {
    ImageComp,
    VueEditor,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      dialog: false,
      editdialog: false,
      editingitem: {},
      content: "",
      tittle: "",
      appLoading: false,
      dialogDelete: false,
      attachments: null,
      attachmentsformData: new FormData(),
      cId: "",
      blogImage: "",
      filePreview: false,
      showFilePreview: false,
      file: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 9,
      blog: [],
    };
  },
  watch: {
    currentPage() {
      // if (this.categoryFilter == "" && this.subCategoryFilter == "") {
      //   this.$store.commit("changeCurrentPage", this.currentPage);
      //   this.getData();
      // } else
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "blogImage") {
        this.blogImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/blogList",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.blog = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      this.appLoading = true;
      var user = {};
      user["content"] = this.content;
      user["tittle"] = this.tittle;

      axios({
        url: "/admin/addBlog",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            var id = response.data.data._id;
            if (this.blogImage) {
              this.uploadImage(id);
            }
            this.dialog = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["content"] = this.editingitem.content;
      user["tittle"] = this.editingitem.tittle;
      user["id"] = this.editingitem._id;
      axios({
        url: "/admin/editBlog",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.blogImage) {
              this.uploadImage(this.editingitem._id);
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      axios({
        url: "/admin/removeBlog",
        method: "POST",
        params: {
          id: r._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.blogImage);

      axios({
        method: "POST",
        url: "/blog/image/addOrEdit",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            formData = new FormData();
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>